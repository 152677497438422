.pmivr-progress-bar {
    .progress-line {
        top: 13px;
        left: 0;
        right: 0;
        height: 1px;
        background-color: $border-color-secondary;
        z-index: 1;
    }

    .progress-line>div {
        height: 100%;
        background-color: $bg-color-primary;
        transition: width 0.4s ease;
    }

    .step-indicator {
        z-index: 2;
    }

    .text-primary {
        color: $color-primary !important;
    }
}