.pmivr-wizard-data {
	.error-msg {
		color: red;
		font-size: 13px;
	}

	&.scroll {
		max-height: calc(100vh - 6.7rem);
		overflow-y: auto;
		width: 99.6%;
		overflow-x: hidden;
	}

	ul.questions {
		margin: 0px;
		padding: 0px;

		li {
			list-style: none;
			margin-left: 1.25rem;

			span {
				position: relative;
				left: -1.25rem;
				font-family: $font-bold;
				top: 1.37rem;
			}
		}
	}

	ol.sub-questions {
		margin: 0px;
		padding: 0px;

		li {
			display: block;
			border-radius: 0.3125rem;
			margin: 0.625rem 0.25rem 0.625rem 0rem;
			padding: 1.25rem;
			background-color: $bg-color-white;
			border: solid 1px $border-color-secondary;
			border-left: solid 0.25rem $border-color-primary;
		}
	}

	.questions .list {

		label {
			color: $color-primary;
			font-size: 1rem;
			font-family: $font-medium;
			width: 100%;
		}

		.form-check {
			label {
				color: $color-black !important;
				font-family: $primary-font;
			}
		}

		ol.sub-questions {
			li {
				label {
					font-size: 0.9375rem;
					color: $color-black;
					font-family: $primary-font;
				}
			}
		}
	}

	.custom-drop {
		width: 12.5rem !important;
	}

	.info-box {
		display: block;
		margin: 0.625rem 1rem;

		.label {
			color: $bg-color-primary;
			font-size: 1rem;
		}

		.label-value {
			color: $color-primary;
			font-size: 1rem;
			margin-right: 2vw;
			font-weight: bold;
		}
	}

	.dnid-info-box {
		max-height: 200px;
		overflow-y: auto
	}

	.warning {
		margin: 0.625rem 1rem;
		color: $color-red;
	}

	.pmivr-select {
		height: 2.4rem;
	}

	.pmivr-add-option {
		color: $color-blue !important;

		&:hover {
			text-decoration: underline;
		}
	}

	.pmivr-table.table-body-block tbody {
		max-height: calc(100vh - 21.5rem);
	}

	.step-title {
		font-size: $font-size-primary;
		font-family: $font-medium;
	}

	.set-up-questions {
		max-height: calc(100vh - 16.5rem);
		overflow-y: scroll;
	}

	.step-content {
		height: calc(100vh - 15.7rem);
	}
}

.pmivr-add-option {
	color: $color-blue !important;

	&:hover {
		text-decoration: underline;
	}
}

.dnid-modal {
	.modal-dialog {
		max-width: 85%;
	}

	.pmivr-table.table-body-block tbody {
		max-height: calc(100vh - 33rem);
	}
}

ul.dnid-variable-heading {
	margin: 0;
	padding: 0;

	li {
		display: inline-block;
		padding: 5px;
		text-align: center;
		list-style-type: none;
	}

	&:nth-child(odd) {
		background: #ffffff;
	}
}

.ext-btns {
	.check-ext-btn {
		padding: 11px 12px !important;
	}

	.valid-ext-icon {
		margin-top: 31px;
	}

	.invalid-ext-icon {
		margin-top: 31px;
	}
}

.wizard-prompt {
	.nav-tabs {
		height: 53px;

		.nav-item {
			padding-bottom: 5px;
			padding-top: 2px;
			margin-bottom: 36px;
			margin-right: 6px;
			border: none;
		}

		.nav-item.show {
			.nav-link {
				border: none;
				border-bottom: solid 3px $border-color-secondary;
				width: 50%;
			}
		}

		.nav-link.active {
			border-bottom: solid 3px $border-color-primary;
			color: $color-primary;
		}

		.nav-link {
			border: none;
			width: 50%;
			font-size: 0.75rem;
			color: $color-secondary;
			padding-left: 0.6rem;
			padding-right: 0.6rem;
		}
	}

	.file-name,
	.file-size {
		span {
			position: static !important;
			font-family: "poppins", sans-serif !important;
		}
	}

	.nav-pills {
		.nav-item {
			margin-right: 7px;
			margin-left: 13px;
			padding-top: 9px;
			padding-bottom: 10px;
			background: transparent;
			border: none;
			margin-top: -9px;
			margin-bottom: -10px;
		}

		.nav-link.active {
			background-color: white !important;
			font-size: 18px;
		}
	}

	.trash-btn {
		font-size: 24px;
	}

	.add-prompt-btn {
		margin-left: 44% !important;
	}
}

.dnid-variables-modal {
	.pmivr-variable-input .rbt-input-main.form-control:disabled {
		background-color: white !important;
		opacity: 0.6 !important;
		border: 2px solid rgb(234 237 247) !important;
		z-index: 9;
	}
}